import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import IDocument from "../../../../Interfaces/workingTime/IWorkingTime";
import IWorkingTimeProp from "../../../../Interfaces/props/IDocumentProp";
import WorkingTimeIcon from "../../../atoms/Icons/DocumentIcon";
import DocumentHeader from "../../headings/WorkingTimeHeader";

const WorkingTimeListItem = (prop: IWorkingTimeProp) => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" d-flex align-items-center media p-4 m-3 mt-1 border-bottom rounded-bottom">
        <WorkingTimeIcon status={prop.workingTime?.status} />
        <div className="">
          <DocumentHeader workingTime={prop.workingTime} />
          <p>{prop.workingTime?.status}</p>
        </div>
      </div>
    </>
  );
};
export default WorkingTimeListItem;
