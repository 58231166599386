import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { IActionButton } from "../../../../Interfaces/IActionButton";
import { useQuery } from "react-query";
import DocumentsClient from "../../../../api/workingTimes/workingTimeClient";
import IRegisterStatusParams from "../../../../Interfaces/IRegisterStatusParams";
import { IWorkingTimeSetCurrentParams } from "../../../../Interfaces/IWorkingTimeSetCurrentParams";

const ActionButton = (prop: IActionButton) => {
  const { t, i18n } = useTranslation();

  const [disabled, setDisabled] = useState(false);
  let status: IRegisterStatusParams = {
    status: prop.status,
  };

  const handleClick = () => {
    // manually refetch
    setDisabled(true);
    setTimeout(function () {
      setDisabled(false);
    }, 500);
    refetch();
  };
  const { data, refetch } = useQuery<Error>(
    "set-current-status",
    async () => {
      return await DocumentsClient.RegisterStatus(status);
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        prop.setCurrentStatus({
          status: "",
          endingDate: new Date(),
          startingDate: new Date(),
          id: -1,
          userId: -1,
        });
      },
    }
  );

  return (
    <Button variant={prop.class} onClick={handleClick} disabled={disabled}>
      {prop.text}
    </Button>
  );
};

export default ActionButton;
