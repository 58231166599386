import React, { useEffect } from "react";
import DangerAlert from "../../../atoms/alerts/DangerAlert";
import TextHeader from "../../../moleculars/headings/Title";
import DocumentsClient from "../../../../api/workingTimes/workingTimeClient";
import { useQuery, useQueryClient } from "react-query";
import IDocument from "../../../../Interfaces/workingTime/IWorkingTime";
import ErrorContainer from "../../../moleculars/Containers/ErrorContainer";
import LoadingContainer from "../../../moleculars/Containers/LoadingContainer";
import IDocumentParams from "../../../../Interfaces/workingTime/IWorkingTimeParams";
import { t } from "i18next";
import FormInput from "../../../atoms/inputs/FormInput";
import { Button, Col, Form, Row } from "react-bootstrap";
import WorkingTimeDetailForm from "../../../moleculars/forms/WorkingTimeDetailForm";
import DefaultArticleChild from "../../../moleculars/Articles/DefaultArticleChild";
import IWorkingTimeParams from "../../../../Interfaces/workingTime/IWorkingTimeParams";
import IWorkingTime from "../../../../Interfaces/workingTime/IWorkingTime";
import WorkingTimeActionList from "../../../moleculars/Lists/WorkingTimeList/WorkingTimeActionList";
import { IWorkingTimeSetCurrentParams } from "../../../../Interfaces/IWorkingTimeSetCurrentParams";
const WorkingTimeStatus = (param: {
  currentStatus: IWorkingTime | null | undefined;
  setCurrentStatus: React.Dispatch<
    React.SetStateAction<IWorkingTime | null | undefined>
  >;
}) => {
  return (
    <>
      <TextHeader
        icon="bi bi-gear"
        message={t("actions")}
        status="a"
      ></TextHeader>
      <DefaultArticleChild>
        <WorkingTimeActionList
          currentStatus={param?.currentStatus?.status}
          setCurrentStatus={param?.setCurrentStatus}
        />
      </DefaultArticleChild>
    </>
  );
};

export default WorkingTimeStatus;
