import React from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const HeaderNavbar = () => {
  const { t } = useTranslation();
  return <Nav className="ms-auto"></Nav>;
};

export default HeaderNavbar;
