import { IAlert } from "../../../Interfaces/IAlert";

const DangerAlert = (alert: IAlert) => {
  return (
    <div className="alert alert-danger m-2 p-2" role="alert">
      {alert.message}
    </div>
  );
};

export default DangerAlert;
