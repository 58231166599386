import React, { SyntheticEvent, useState } from "react";

import {
  Button,
  Container,
  FloatingLabel,
  Form,
  Navbar,
} from "react-bootstrap";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import LoginHeader from "../../components/organisms/headers/LoginHeader";
import Title from "../../components/moleculars/headings/Title";
import LoginForm from "../../components/moleculars/forms/LoginForm";
import LogoHeader from "../../components/moleculars/headings/LogoHeader";
import { useTranslation } from "react-i18next";

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <LoginHeader />
      <Container
        fluid
        className="d-flex justify-content-center align-items-center vh-90"
      >
        <Row>
          {<LogoHeader />}
          <div className="d-flex justify-content-center align-items-center">
            <div className="border-top-0 w-100  mx-3 rounded-bottom shadow">
              <LoginForm />
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default LoginPage;
