import { t } from "i18next";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import IWorkingTimeProp from "../../../Interfaces/props/IDocumentProp";

const WorkingTimeDetailForm = (prop: IWorkingTimeProp) => {
  var startingDate =
    prop.workingTime && new Date(prop.workingTime.startingDate);
  var startingDatetimeOfDay =
    startingDate &&
    `${startingDate?.getHours()}:${("0" + startingDate?.getMinutes()).slice(
      -2
    )}`;
  var endingDate = prop.workingTime && new Date(prop.workingTime.endingDate);
  var endingDatetimeOfDay =
    endingDate &&
    `${endingDate?.getHours()}:${("0" + endingDate?.getMinutes()).slice(-2)}`;
  return (
    <Form className="p-4">
      <Row>
        <Col>
          {prop.workingTime && prop.workingTime.startingDate && (
            <Form.Floating className="mb-3">
              <FloatingLabel
                controlId="floatingTextarea"
                label={t("startingTimeOfDay")}
                className="mb-3"
              >
                <Form.Control
                  className="border-0 border-bottom "
                  type="text"
                  placeholder="Id"
                  value={startingDatetimeOfDay && startingDatetimeOfDay}
                  disabled
                />
              </FloatingLabel>
            </Form.Floating>
          )}
          <Form.Floating className="mb-3">
            <FloatingLabel
              controlId="floatingTextarea"
              label={t("status")}
              className="mb-3"
            >
              <Form.Control
                className="border-0 border-bottom "
                type="text"
                placeholder="Id"
                value={
                  (prop.workingTime && prop.workingTime.status) || "Poza pracą"
                }
                disabled
              />
            </FloatingLabel>
          </Form.Floating>
        </Col>
        {prop.workingTime && prop.workingTime.endingDate && (
          <Col xs="12" md="6">
            <Form.Floating className="mb-3">
              <FloatingLabel
                controlId="floatingTextarea"
                label={t("endingTimeOfDay")}
                className="mb-3"
              >
                <Form.Control
                  className="border-0 border-bottom "
                  type="text"
                  placeholder="endingTimeOfDay"
                  value={endingDatetimeOfDay}
                  disabled
                />
              </FloatingLabel>
            </Form.Floating>
          </Col>
        )}
      </Row>
    </Form>
  );
};
export default WorkingTimeDetailForm;
