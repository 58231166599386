import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import IDocument from "../../../../Interfaces/workingTime/IWorkingTime";
import { ICountry, ICountryListProps } from "../../../../Interfaces/ICountry";
import IDocuments from "../../../../Interfaces/props/IDocumentsProps";
import LangSwitcherLink from "../../../atoms/links/LangSwitcherLink";
import DocumentIcon from "../../../atoms/Icons/DocumentIcon";
import WorkingTimeListItem from "./WorkingTimeListItem";
import IWorkingTimes from "../../../../Interfaces/props/IDocumentsProps";
import IWorkingTimeStatusesDuration from "../../../../Interfaces/workingTime/IWorkingTimeStatusesDuration";
import WorkingTimeStatusDurationItem from "./WorkingTimeStatusDurationItem";

const WorkingTimeStatuesDurations = (props: IWorkingTimeStatusesDuration) => {
  return (
    <>
      {props.prop?.map((workingTimeStatus, index) => {
        return (
          <WorkingTimeStatusDurationItem
            duration={workingTimeStatus.duration}
            status={workingTimeStatus.status}
            key={workingTimeStatus.status + workingTimeStatus.duration}
          />
        );
      })}
    </>
  );
};
export default WorkingTimeStatuesDurations;
