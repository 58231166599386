import i18next from "i18next";
import { ICountry, ICountryListProps } from "../../../../Interfaces/ICountry";
import LangSwitcherLink from "../../../atoms/links/LangSwitcherLink";

const langSwitcherList = (props: ICountryListProps) => {
  return (
    <>
      {props.countries.map((country) => (
        <LangSwitcherLink
          countryCode={country.code}
          displayCode={country.displayCode}
          code={country.code}
          label=""
          key={country.code}
        />
      ))}
    </>
  );
};
export default langSwitcherList;
