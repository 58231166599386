import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import IDocument from "../../../../Interfaces/workingTime/IWorkingTime";
import { ICountry, ICountryListProps } from "../../../../Interfaces/ICountry";
import IDocuments from "../../../../Interfaces/props/IDocumentsProps";
import LangSwitcherLink from "../../../atoms/links/LangSwitcherLink";
import DocumentIcon from "../../../atoms/Icons/DocumentIcon";
import WorkingTimeListItem from "./WorkingTimeListItem";
import IWorkingTimes from "../../../../Interfaces/props/IDocumentsProps";

const WorkingTimeList = (props: IWorkingTimes) => {
  return (
    <>
      {props.workingTimes?.map((workingTime, index) => {
        return (
          <WorkingTimeListItem workingTime={workingTime} key={workingTime.id} />
        );
      })}
    </>
  );
};
export default WorkingTimeList;
