import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import IDocument from "../../../../Interfaces/workingTime/IWorkingTime";
import { ICountry, ICountryListProps } from "../../../../Interfaces/ICountry";
import IDocuments from "../../../../Interfaces/props/IDocumentsProps";
import LangSwitcherLink from "../../../atoms/links/LangSwitcherLink";
import DocumentIcon from "../../../atoms/Icons/DocumentIcon";
import IWorkingTimes from "../../../../Interfaces/props/IDocumentsProps";
import IWorkingTimeProp from "../../../../Interfaces/props/IDocumentProp";
import { Button, Col, Row } from "react-bootstrap";
import IWorkingTimeParams from "../../../../Interfaces/workingTime/IWorkingTimeParams";
import ActionButton from "../../actions/workingTime/WorkingTimeActionButton";
import EndWorkButton from "../../actions/workingTime/EndWorkButton";
import { useTranslation } from "react-i18next";
import { IWorkingTimeSetCurrentParams } from "../../../../Interfaces/IWorkingTimeSetCurrentParams";

const WorkingTimeActionList = (params: IWorkingTimeParams) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="container-fluid d-grid gap-4 p-4">
        {params.currentStatus == "w trakcie pracy" && (
          <ActionButton
            class="info"
            status="Podczas przerwy"
            text={t("startBreak")}
            setCurrentStatus={params.setCurrentStatus}
          ></ActionButton>
        )}

        {params.currentStatus == "Podczas przerwy" && (
          <ActionButton
            class="success"
            status="w trakcie pracy"
            text={t("backToWork")}
            setCurrentStatus={params.setCurrentStatus}
          ></ActionButton>
        )}
        {!params.currentStatus && (
          <ActionButton
            class="success"
            status="w trakcie pracy"
            text={t("startWork")}
            setCurrentStatus={params.setCurrentStatus}
          ></ActionButton>
        )}
        {params.currentStatus && (
          <EndWorkButton
            class="dark"
            status="Zakończ pracę"
            text={t("endWork")}
            setCurrentStatus={params.setCurrentStatus}
          ></EndWorkButton>
        )}
        {/* <Button variant="info"> Rozpocznij przerwę</Button>
        <Button variant="success">Powrót do pracy</Button>
        <Button variant="dark">Wyjdź</Button> */}
      </div>
    </>
  );
};
export default WorkingTimeActionList;
