import React from "react";
import { Navbar } from "react-bootstrap";
import {
  ArrowRightCircleFill,
  CcCircleFill,
  CircleFill,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { IWorkingTimeIcon } from "../../../Interfaces/IWorkingTimeIcon";
const WorkingTimeIcon = (status: IWorkingTimeIcon) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="p-2">
      {status.status == "w trakcie pracy" && (
        <i className="status-icon bg-success"> </i>
      )}
      {status.status == "Podczas przerwy" && (
        <i className="status-icon bg-info"> </i>
      )}
      {!status.status && <i className="status-icon bg-dark"> </i>}
    </div>
  );
};

export default WorkingTimeIcon;
