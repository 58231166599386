import React from "react";
import { ISpinner } from "../../../Interfaces/ISpinner";

const LoadingSpinner = (spinner: ISpinner) => {
  return (
    <div className="spinner-border" role="status">
      <span className="visually-hidden">{spinner.message}</span>
    </div>
  );
};

export default LoadingSpinner;
