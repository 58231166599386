import { t } from "i18next";
import React from "react";
import { IErrorProp } from "../../../Interfaces/props/IErrorProp";
import DangerAlert from "../../atoms/alerts/DangerAlert";
import FluidContainer from "./FluidContainer";
const getMessage = (code: number | undefined) => {
  switch (code) {
    case 401:
      return "unAuthorizedMessage";
    case 404:
      return "notFoundMessage";
    default:
      return "defaultError";
  }
};

const ErrorContainer = (error: IErrorProp) => {
  return (
    <FluidContainer>
      <DangerAlert message={t(getMessage(error.code))} />
    </FluidContainer>
  );
};

export default ErrorContainer;
