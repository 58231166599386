import i18next from "i18next";
import { ICountry } from "../../../Interfaces/ICountry";

const langSwitcherLink = (country: ICountry) => {
  return (
    <li className="langswitcher__element" key={country.countryCode}>
      <a
        className="langswitcher__link"
        href="#"
        onClick={() => i18next.changeLanguage(country.code)}
      >
        {country.displayCode}
      </a>
    </li>
  );
};
export default langSwitcherLink;
