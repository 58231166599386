import React from "react";
import { Navigate, Route } from "react-router-dom";
import { getCurrentUser } from "../../../api/auth/authClient";
import Login from "../forms/LoginForm";

const ProtectedRoute = ({ user, redirectPath = "/login", children }: any) => {
  const token = getCurrentUser();
  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
export default ProtectedRoute;
