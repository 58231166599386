import React from "react";
import IDocument from "../../../Interfaces/workingTime/IWorkingTime";
import { IHeader } from "../../../Interfaces/IHeader";
import IWorkingTimeProp from "../../../Interfaces/props/IDocumentProp";
import SearchForm from "../Search/Search";

const WorkingTimeHeader = (prop: IWorkingTimeProp) => {
  var startingDate =
    prop.workingTime && new Date(prop.workingTime.startingDate);
  var startingDatetimeOfDay = `${startingDate?.getHours()}:${(
    "0" + startingDate?.getMinutes()
  ).slice(-2)}`;
  var endingDate = prop.workingTime && new Date(prop.workingTime.endingDate);
  var endingDatetimeOfDay = `${endingDate?.getHours()}:${(
    "0" + endingDate?.getMinutes()
  ).slice(-2)}`;
  return (
    <h5 className="fw-bold">
      {startingDatetimeOfDay}
      {prop.workingTime && prop.workingTime.endingDate && (
        <small className="ms-1 text-muted">/ {endingDatetimeOfDay}</small>
      )}
    </h5>
  );
};

export default WorkingTimeHeader;
