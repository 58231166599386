import http from "../../axios/axios";
import { ILoginUser } from "../../Interfaces/users/ILoginUser";
import { IToken } from "../../Interfaces/users/IToken";

export const restLogin = async (
  iLoginUser: Partial<ILoginUser> | undefined
) => {
  const response = await http.post<IToken>("auth/login", iLoginUser);
  if (response.data.token) {
    localStorage.setItem("token", JSON.stringify(response.data));
  }
  return response.data;
};

export const logout = () => {
  localStorage.removeItem("token");
  window.location.href = process.env.PUBLIC_URL;
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("token");
  if (userStr) return userStr;

  return null;
};
export default function authHeader() {
  const userStr = localStorage.getItem("token");
  let user = null;
  if (userStr) user = JSON.parse(userStr);

  if (user && user.token) {
    return { Authorization: "Bearer " + user.token }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return { Authorization: "" }; // for Spring Boot back-end
    // return { 'x-access-token': null }; // for Node Express back-end
  }
}
