import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import NavigationBar from "../../components/moleculars/navigations/NavigationBar";
import Title from "../../components/moleculars/headings/Title";
import DefaultArticle from "../../components/moleculars/Articles/DefaultArticle";
import { Row } from "react-bootstrap";
import { QueryClient, QueryClientProvider } from "react-query";
import Document from "../../components/organisms/Articles/WorkingTime/WorkingTime";
import Documents from "../../components/organisms/Articles/WorkingTime/WorkingTimes";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import WorkingTime from "../../components/organisms/Articles/WorkingTime/WorkingTime";
import WorkingTimes from "../../components/organisms/Articles/WorkingTime/WorkingTimes";
import WorkingTimeActions from "../../components/organisms/Articles/WorkingTime/WorkingTimeActions";
import IWorkingTimeParams from "../../Interfaces/workingTime/IWorkingTimeParams";
import IWorkingTime from "../../Interfaces/workingTime/IWorkingTime";
import WorkingTimeStatuesDurations from "../../components/moleculars/Lists/WorkingTimeList/WorkingTimeStatusesDurations";
import WorkingTimeStatusesDuration from "../../components/organisms/Articles/WorkingTime/WorkingTimeStatusesDuration";

const Home = () => {
  const [currentStatus, setCurrentStatus] = useState<IWorkingTime | null>();
  const pageVariants = {
    initial2: {
      translateY: "100vh",
    },
    in2: {
      translateY: 0,
    },
    out2: {
      translateY: "-100vh",
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.25,
  };
  const { t, i18n } = useTranslation();
  return (
    <>
      {/* <NavigationBar /> */}
      <motion.div
        variants={pageVariants}
        transition={pageTransition}
        initial="initial2"
        animate="in2"
        exit="out2"
        key={"test4"}
        className="motion-index"
      >
        <Container className="justify-content-center">
          <Row className="w-100">
            <DefaultArticle
              element={
                <WorkingTime
                  setCurrentStatus={setCurrentStatus}
                  currentStatus={currentStatus}
                />
              }
              flexCol="6"
              flexOrder="2"
            />

            <DefaultArticle
              element={
                <WorkingTimeActions
                  currentStatus={currentStatus}
                  setCurrentStatus={setCurrentStatus}
                />
              }
              flexCol="6"
              flexOrder="1"
            />
            <DefaultArticle
              element={<WorkingTimes currentStatus={currentStatus} />}
              flexCol="6"
              flexOrder="3"
            />
            <DefaultArticle
              element={
                <WorkingTimeStatusesDuration currentStatus={currentStatus} />
              }
              flexCol="6"
              flexOrder="4"
            />
          </Row>
        </Container>
      </motion.div>
    </>
  );
};

export default Home;
