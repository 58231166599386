import React, { useEffect } from "react";
import DangerAlert from "../../../atoms/alerts/DangerAlert";
import TextHeader from "../../../moleculars/headings/Title";
import DocumentsClient from "../../../../api/workingTimes/workingTimeClient";
import { useQuery, useQueryClient } from "react-query";
import IDocument from "../../../../Interfaces/workingTime/IWorkingTime";
import ErrorContainer from "../../../moleculars/Containers/ErrorContainer";
import LoadingContainer from "../../../moleculars/Containers/LoadingContainer";
import IDocumentsParams from "../../../../Interfaces/workingTime/IDocumentsParams";
import log from "loglevel";
import { useTranslation } from "react-i18next";
import { Card, Pagination } from "react-bootstrap";
// import "./Documents.scss";
import { useNavigate } from "react-router-dom";
import DocumentList from "../../../moleculars/Lists/WorkingTimeList/WorkingTimeList";
import DefaultArticleChild from "../../../moleculars/Articles/DefaultArticleChild";
import WorkingTimeList from "../../../moleculars/Lists/WorkingTimeList/WorkingTimeList";
import IWorkingTime from "../../../../Interfaces/workingTime/IWorkingTime";
import IWorkingTimeStatusDuration from "../../../../Interfaces/workingTime/IWorkingTimeStatusDuration";
import WorkingTimeStatusesDurations from "../../../moleculars/Lists/WorkingTimeList/WorkingTimeStatusesDurations";

const WorkingTimeStatusesDuration = (param: {
  currentStatus: IWorkingTime | null | undefined;
}) => {
  log.info("WorkingTimeStatusesDuration()");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, isSuccess, isError, data, error, refetch, isFetching } =
    useQuery<Array<IWorkingTimeStatusDuration> | undefined, Error>(
      "get-statues-duration-list",
      async () => {
        return await DocumentsClient.GetStatusesTimeDuration();
      },
      {
        retry: 1,
        retryDelay: 500,
      }
    );

  useEffect(() => {
    refetch();
  }, [param.currentStatus?.status]);
  let active = 2;
  return (
    <div>
      <TextHeader
        icon="bi bi-clock"
        message={t("listOfWorkingTimesStatuesDurations")}
        status="a"
      ></TextHeader>
      <DefaultArticleChild>
        {(isLoading || isFetching) && <LoadingContainer />}
        {isError && <ErrorContainer code={Number(400)} />}
        {isSuccess && !isLoading && (
          <WorkingTimeStatusesDurations prop={data || undefined} />
        )}
      </DefaultArticleChild>
    </div>
  );
};

export default WorkingTimeStatusesDuration;
