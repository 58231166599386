import React from "react";
import ReactDOM from "react-dom/client";
import "./configs/i18n";
import {
  BrowserRouter,
  HashRouter,
  Routes,
  createRoutesFromChildren,
  useLocation,
  useRoutes,
} from "react-router-dom";
import "./index.css";
import { AnimatePresence, motion } from "framer-motion";
import AppRoutes from "./AppRoutes";

function App() {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait" key={"animatePresence"}>
      <Routes location={location} key={location.pathname}>
        {AppRoutes()}
      </Routes>
    </AnimatePresence>
  );
}

export default App;
