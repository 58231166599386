import IRegisterStatusParams from "../../Interfaces/IRegisterStatusParams";
import IWorkingTimes from "../../Interfaces/workingTime/IWorkingTime";
import IResponseProp from "../../Interfaces/workingTime/IResponse";
import http from "../../axios/axios";
import authHeader, { logout } from "../auth/authClient";
import { Exception } from "sass";
import log from "loglevel";
import { IStatus } from "../../Interfaces/IStatus";
import { AxiosError } from "axios";
import IWorkingTimeStatusDuration from "../../Interfaces/workingTime/IWorkingTimeStatusDuration";
class DocumentsClient {
  getTodays = async () => {
    var response = await http
      .get<Array<IWorkingTimes>>("/WorkingTime/GetTodays", {
        headers: authHeader(),
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logout();
          return error.response;
        }
        log.error({ error }); // this will log an empty object with an error property
        return { status: 400 };
      });
    return response.data;
  };

  GetCurrentStatus = async () => {
    var response = await http
      .get<IWorkingTimes>(`/WorkingTime/GetCurrentStatus`, {
        headers: authHeader(),
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logout();
          return error.response;
        }
        log.error({ error }); // this will log an empty object with an error property
        return { status: 400 };
      });
    return response.data;
  };
  RegisterStatus = async (status: IRegisterStatusParams) => {
    var response = await http
      .post(`/WorkingTime/RegisterStatus`, status, {
        headers: authHeader(),
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logout();
          return error.response;
        }
        log.error({ error }); // this will log an empty object with an error property
        return { status: 400 };
      });
    return response.data;
  };
  ExitFromWork = async () => {
    var response = await http
      .post(
        `/WorkingTime/Exit`,
        {},
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logout();
          return error.response;
        }
        log.error({ error }); // this will log an empty object with an error property
        return { status: 400 };
      });

    return response.data;
  };
  GetStatusesTimeDuration = async () => {
    var response = await http
      .get<Array<IWorkingTimeStatusDuration>>(
        "/WorkingTime/GetStatusesTimeDuration",
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logout();
          return error.response;
        }
        log.error({ error }); // this will log an empty object with an error property
        return { status: 400 };
      });
    return response.data;
  };
}

export default new DocumentsClient();
