import { useTranslation } from "react-i18next";
import React from "react";
import { Button } from "react-bootstrap";
import { IActionButton } from "../../../../Interfaces/IActionButton";
import { useQuery } from "react-query";
import DocumentsClient from "../../../../api/workingTimes/workingTimeClient";

const EndWorkButton = (prop: IActionButton) => {
  const { t, i18n } = useTranslation();
  const handleClick = () => {
    refetch();
  };
  const { data, refetch } = useQuery<Error>(
    "end-work-status",
    async () => {
      return await DocumentsClient.ExitFromWork();
    },
    {
      retry: 2,
      retryDelay: 500,
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        prop.setCurrentStatus({
          status: "",
          endingDate: new Date(),
          startingDate: new Date(),
          id: -1,
          userId: -1,
        });
      },
    }
  );

  return (
    <Button variant={prop.class} onClick={handleClick}>
      {prop.text}
    </Button>
  );
};

export default EndWorkButton;
