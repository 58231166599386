import { Container } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import Header from "../headers/DefaultHeader";
import Footer from "../footer/Footer";

const Layout = () => {
  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap"
        rel="stylesheet"
      ></link>
      <Container fluid>
        <Header />
      </Container>
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
