import { IArticle } from "../../../Interfaces/IArticle";

const DefaultArticle = (articleBody: IArticle) => {
  return (
    <article
      className={`col-md-${articleBody.flexCol} col-xs-12 order-${articleBody.flexOrder}`}
    >
      {articleBody.element}
    </article>
  );
};

export default DefaultArticle;
