import { Route } from "use-react-router-breadcrumbs";
import LoginPage from "./pages/authorizationPages/LoginPage";
import ProtectedRoute from "./components/moleculars/routers/AuthRoute";
import Layout from "./components/organisms/layouts/Layout";
import Home from "./pages/home/indexPage";
import { Routes, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AppRoutes = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Route
        path="/"
        element={<ProtectedRoute user="">{<Layout />}</ProtectedRoute>}
      >
        <Route path="/" element={<Home />} breadcrumb={t("home")} />
      </Route>
      <Route path="login" element={<LoginPage />}></Route>
    </>
  );
};
export default AppRoutes;
