import IWorkingTimeStatusDuration from "../../../Interfaces/workingTime/IWorkingTimeStatusDuration";
const toHoursAndMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
};
const padToTwoDigits = (num: number) => {
  return num.toString().padStart(2, "0");
};
const WorkingTimeStatusHeader = (prop: IWorkingTimeStatusDuration) => {
  return <h5 className="fw-bold">{toHoursAndMinutes(prop.duration)}</h5>;
};

export default WorkingTimeStatusHeader;
