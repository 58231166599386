import { useTranslation } from "react-i18next";
import React from "react";
import { logout } from "../../../api/auth/authClient";
import { Button } from "react-bootstrap";

const LogoutButton = () => {
  const { t, i18n } = useTranslation();
  return (
    <Button
      type="button"
      className="btn my-2 mx-2"
      id="logout"
      onClick={logout}
    >
      {t("logout")}
    </Button>
  );
};

export default LogoutButton;
