import React from "react";
import FluidContainer from "./FluidContainer";
import LoadingSpinner from "../../atoms/Spinners/LoadingSpinner";
import { t } from "i18next";

const LoadingContainer = () => {
  return (
    <FluidContainer>
      <div className="d-flex justify-content-center " role="alert">
        <LoadingSpinner message={t("loading")} />
      </div>
    </FluidContainer>
  );
};

export default LoadingContainer;
