import { t } from "i18next";
import React from "react";
import { IHeader } from "../../../Interfaces/IHeader";
import SearchForm from "../Search/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Title = (header: IHeader) => {
  return (
    <div className="d-flex align-items-center p-3 mt-3 article-header rounded shadow border-top-0  ">
      <div className="fs-2 me-auto">
        <i className={header.icon + ""} />
      </div>
      <div className="lh-100 ml-auto">
        <h6 className="mb-0 text-dark-50 lh-100">{header.message}</h6>
        {header.status == "w trakcie pracy" && (
          <small className="status-icon bg-success"> </small>
        )}
        {header.status == "Podczas przerwy" && (
          <small className="status-icon bg-info"> </small>
        )}
        {!header.status && <small className="status-icon bg-dark"> </small>}
      </div>
    </div>
  );
};

export default Title;
