import React, { useEffect } from "react";
import DangerAlert from "../../../atoms/alerts/DangerAlert";
import TextHeader from "../../../moleculars/headings/Title";
import DocumentsClient from "../../../../api/workingTimes/workingTimeClient";
import { useQuery, useQueryClient } from "react-query";
import IDocument from "../../../../Interfaces/workingTime/IWorkingTime";
import ErrorContainer from "../../../moleculars/Containers/ErrorContainer";
import LoadingContainer from "../../../moleculars/Containers/LoadingContainer";
import IDocumentParams from "../../../../Interfaces/workingTime/IWorkingTimeParams";
import { t } from "i18next";
import FormInput from "../../../atoms/inputs/FormInput";
import { Col, Form, Row } from "react-bootstrap";
import WorkingTimeDetailForm from "../../../moleculars/forms/WorkingTimeDetailForm";
import DefaultArticleChild from "../../../moleculars/Articles/DefaultArticleChild";
import IWorkingTimeParams from "../../../../Interfaces/workingTime/IWorkingTimeParams";
import { IWorkingTimeSetCurrentParams } from "../../../../Interfaces/IWorkingTimeSetCurrentParams";
import IWorkingTime from "../../../../Interfaces/workingTime/IWorkingTime";
const WorkingTime = (param: {
  currentStatus: IWorkingTime | null | undefined;
  setCurrentStatus: React.Dispatch<
    React.SetStateAction<IWorkingTime | null | undefined>
  >;
}) => {
  const { isLoading, isSuccess, isError, data, error, refetch, isFetching } =
    useQuery<IWorkingTime | undefined, Error>(
      "get-document",
      async () => {
        var result = await DocumentsClient.GetCurrentStatus();
        if (param.currentStatus?.status != result?.status)
          param.setCurrentStatus(result);
        return result;
      },
      { retry: 1, retryDelay: 500, refetchOnWindowFocus: false }
    );
  useEffect(() => {
    refetch();
  }, [param.currentStatus?.status]);

  return (
    <>
      <TextHeader
        icon="bi bi-info-square"
        message=""
        status={data?.status}
      ></TextHeader>
      <DefaultArticleChild>
        {(isLoading || isFetching) && <LoadingContainer />}
        {isError && <ErrorContainer code={Number(400)} />}
        {isSuccess && !(isLoading || isFetching) && (
          <WorkingTimeDetailForm workingTime={data} />
        )}
      </DefaultArticleChild>
    </>
  );
};

export default WorkingTime;
