import React from "react";
import i18next from "i18next";
import langSwitcherList from "../../../moleculars/Lists/LangswitcherList/LangSwitcherList";
import LangSwitcherList from "../../../moleculars/Lists/LangswitcherList/LangSwitcherList";
import { ICountry } from "../../../../Interfaces/ICountry";
import "./langswitcher.scss";
const languages: Array<ICountry> = [
  {
    code: "pl",
    label: "Polski",
    countryCode: "pl",
    displayCode: "PL",
  },
  {
    code: "en",
    label: "English",
    countryCode: "gb",
    displayCode: "EN",
  },
];

const LangSwitcher = () => {
  return (
    <div className="langswitcher">
      <ul className="langswitcher__list">
        <LangSwitcherList countries={languages} />
      </ul>
    </div>
  );
};
export default LangSwitcher;
