import { t } from "i18next";
import { SyntheticEvent, useState } from "react";
import { Button, Container, FloatingLabel, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Outlet, Link } from "react-router-dom";
import { restLogin } from "../../../api/auth/authClient";
import { ILoginUser } from "../../../Interfaces/users/ILoginUser";
import Header from "../../organisms/headers/DefaultHeader";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { IToken } from "../../../Interfaces/users/IToken";
import log from "loglevel";
import DangerAlert from "../../atoms/alerts/DangerAlert";
import ErrorContainer from "../Containers/ErrorContainer";
import LoadingContainer from "../Containers/LoadingContainer";

const LoginForm = () => {
  const { t, i18n } = useTranslation();
  let enableQuery = false;
  const [User, setLoginUser] = useState<Partial<ILoginUser>>();
  const { isLoading, isSuccess, isError, data, error, refetch, isFetching } =
    useQuery<IToken, AxiosError>(
      "login-user",
      async () => {
        let res = await restLogin(User);
        return res;
      },
      {
        enabled: enableQuery,
        retryDelay: 100,
        onError: (error) =>
          log.error(`Login error = ${User?.login}: ${error.message}`),
        onSuccess(data) {
          if (data.token) window.location.href = process.env.PUBLIC_URL;
        },
      }
    );
  const onSubmitHandler = async (event: SyntheticEvent) => {
    event.preventDefault();
    enableQuery = true;
    refetch();
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    event.preventDefault();
    if (event.keyCode === 13 || event.which === 13) {
      onSubmitHandler(event);
    }
  };
  return (
    <>
      {isError && <ErrorContainer code={error?.response?.status} />}
      {(isLoading || isFetching) && <LoadingContainer />}
      <Form className="p-4" onSubmit={onSubmitHandler}>
        <Form.Floating className="mb-3">
          <FloatingLabel
            controlId="floatingTextarea"
            label={t("login")}
            className="mb-3"
          >
            <Form.Control
              className="border-0 border-bottom "
              type="text"
              placeholder="Login"
              onChange={(e) => setLoginUser({ ...User, login: e.target.value })}
              autoFocus
            />
          </FloatingLabel>
        </Form.Floating>
        <Form.Floating className="mb-3">
          <FloatingLabel
            controlId="floatingPassword"
            label={t("password")}
            className="mb-3"
          >
            <Form.Control
              className="border-0 border-bottom "
              type="password"
              placeholder="Password"
              onChange={(e) =>
                setLoginUser({ ...User, password: e.target.value })
              }
            />
          </FloatingLabel>
        </Form.Floating>
        <Button variant="primary" type="submit" className="w-100">
          {t("signIn")}
        </Button>
      </Form>
    </>
  );
};

export default LoginForm;
